import { WalkthroughInfo } from 'hooks/jobs/walkthrough/useWalkthrough';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export type WalkthroughStore = {
  // Key-value pair of Job id to Walkthrough detail
  walkthroughs: Record<string, WalkthroughInfo>;
};

export const initialState: WalkthroughStore = {
  walkthroughs: {},
};

export const walkthroughStore = createSlice({
  name: 'walkthroughs',
  initialState,
  reducers: {
    setWalkthrough: (
      state,
      {
        payload: { quoteId, walkthrough },
      }: PayloadAction<{ quoteId: string; walkthrough: WalkthroughInfo }>
    ) => {
      state.walkthroughs[quoteId] = walkthrough;
    },
    deleteWalkthrough: (
      state,
      { payload: { quoteId } }: PayloadAction<{ quoteId: string }>
    ) => {
      delete state.walkthroughs[quoteId];
    },
  },
});

export const { deleteWalkthrough, setWalkthrough } = walkthroughStore.actions;

export default walkthroughStore.reducer;
