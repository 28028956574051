import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

declare module '@mui/material/styles' {
  interface Palette {
    tertiary: Palette['primary'];
    surface: Palette['primary'];
    surfaceSubdued: Palette['primary'];
    content: Palette['primary'];
    outline: Palette['primary'];
    interactive: Palette['primary'];
    interactivePrimary: Palette['primary'];
    interactiveSecondary: Palette['primary'];
    interactiveTertiary: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    tertiary?: PaletteOptions['primary'];
    surface?: PaletteOptions['primary'];
    surfaceSubdued?: PaletteOptions['primary'];
    content: PaletteOptions['primary'];
    outline?: PaletteOptions['primary'];
    interactive?: PaletteOptions['primary'];
    interactivePrimary?: PaletteOptions['primary'];
    interactiveSecondary?: PaletteOptions['primary'];
    interactiveTertiary?: PaletteOptions['primary'];
  }

  interface PaletteOptionsOverrides {
    interactive: true;
    interactivePrimary: true;
    interactiveSecondary: true;
    interactiveTertiary: true;
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true;
    content: true;
    outline: true;
    interactivePrimary: true;
    interactiveSecondary: true;
    interactiveTertiary: true;
  }
}

// Update the Button's color prop options
declare module '@mui/material/AppBar' {
  interface AppBarPropsColorOverrides {
    tertiary: true;
    content: true;
    outline: true;
  }
}
// Update the ButtonGroup's color prop options
declare module '@mui/material/ButtonGroup' {
  interface ButtonGroupPropsColorOverrides {
    tertiary: true;
    content: true;
    outline: true;
  }
}

// Update the Tabs's color prop options
declare module '@mui/material/Tabs' {
  interface TabsPropsIndicatorColorOverrides {
    tertiary: true;
    content: true;
    outline: true;
  }
}
// Update the Checkbox's color prop options
declare module '@mui/material/Checkbox' {
  interface CheckboxPropsColorOverrides {
    tertiary: true;
    outline: true;
  }
}

// Update the LinearProgress's color prop options
declare module '@mui/material/LinearProgress' {
  interface LinearProgressPropsColorOverrides {
    tertiary: true;
    outline: true;
  }
}

// Update the LinearProgress's color prop options
declare module '@mui/material/InputLabel' {
  interface InputLabelPropsColorOverrides {
    tertiary: true;
    outline: true;
    content: true;
  }
}

// Update the LinearProgress's color prop options
declare module '@mui/material/TextField' {
  interface TextFieldPropsColorOverrides {
    tertiary: true;
    outline: true;
    content: true;
  }
}

// Update the LinearProgress's color prop options
declare module '@mui/material/Badge' {
  interface BadgePropsColorOverrides {
    tertiary: true;
    outline: true;
    content: true;
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    // removes the `initial` breakpoints
    xs: false;
    sm: false;
    md: false;
    lg: false;
    xl: false;
    // adds the `custom` breakpoints
    zero: true;
    startMobile: true;
    endMobile: true;
    startSmallTablet: true;
    endLargeTablet: true;
    startLaptop: true;
    endLaptop: true;
    desktop: true;
    extraLarge: true;
  }
}
/*

**** colors used but not in theme ****
  #F6F8FA - photo background on review page
  #A6B0BF - midtone grey
  #BDE3FF - light blue badge background
  #D1E1FA - border of recommended system on PDP
  #EBB447 - dull yellow badge background

*/

// Create a theme instance.
let theme = createTheme({
  palette: {
    primary: {
      light: '#DAF5A3', // tetra green
      main: '#B4EB47', // tetra green
      dark: '#618A0F', // tetra green
    },
    secondary: {
      light: '#FFFFFF',
      main: '#1A1E23', // very dark navy blue -- only referenced in customizations remove from project
      dark: '#566376', // light cool grey
    },
    tertiary: {
      main: '#ffffff',
    },
    error: {
      main: red.A400,
    },
    surface: {
      light: '#E1E5EA', // light grey
      main: '#0F408A', // blue *** VARIABLE NOT REFERENCED IN CODE (used in CSS files) ***
      dark: '#EDEFF2', // light grey, used in progress bar
    },
    surfaceSubdued: {
      light: '#F3F5F7', // very light grey
      main: '#061A37', // navy blue *** NOT REFERENCED IN CODE ***
      dark: '#2B313B', // dark grey - a11y compliant against white *** NOT REFERENCED IN CODE ***
    },
    content: {
      light: '#15191E', // almost black
      main: '#FFFFFF',
      dark: '#FFFFFF',
    },
    outline: {
      light: '#E1E5EA', // light grey
      main: '#1456B8', // blue
      dark: '#566376', // cool grey
    },
    success: {
      light: '#ffffff',
      main: '#4789EB', // bright blue -- used in several places, possibly called directly
      dark: '#1456B8', // blue (duplicates outline.main)
    },
    text: {
      primary: '#000000',
    },
    interactive: {
      main: '#1A6BE5',
    },
    interactivePrimary: {
      main: '#B4EB47',
    },
    interactiveSecondary: {
      main: '#E3EDFC',
    },
    interactiveTertiary: {
      main: '#FFF',
    },
  },
  typography: {
    fontFamily: 'Sharp Grotesk',
    h4: {
      fontSize: '32px',
      fontWeight: 500,
      lineHeight: '40px',
    },
    subtitle2: {
      fontSize: '24px',
      fontWeight: 400,
      lineHeight: '30px',
    },
  },
  breakpoints: {
    values: {
      zero: 0,
      startMobile: 320,
      endMobile: 480,
      startSmallTablet: 481,
      endLargeTablet: 801,
      startLaptop: 801,
      endLaptop: 1024,
      desktop: 1024,
      extraLarge: 1280,
    },
  },
});

theme = createTheme(theme, {
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Untitled Sans';
          font-style: normal;
          font-weight: 400;
          font-display: swap;
          src: local('Untitled Sans'), local('UntitledSans-Regular'),
            url(/fonts/UntitledSans/UntitledSans-Regular.ttf)
              format('truetype');
            url(/fonts/UntitledSans/UntitledSans-Regular.eot)
              format('embedded-opentype');
            url(/fonts/UntitledSans/UntitledSans-Regular.otf)
              format('opentype');
            url(/fonts/UntitledSans/UntitledSans-Regular.svg#UntitledSans-Regular)
              format('svg');
            url(/fonts/UntitledSans/UntitledSans-Regular.woff)
              format('woff');
            url(/fonts/UntitledSans/UntitledSans-Regular.woff2)
              format('woff2');
          unicode-range: U+0100-024f, U+1-1eff, U+20a0-20ab, U+20ad-20cf,
            U+2c60-2c7f, U+A720-A7FF;
        }
        @font-face {
          font-family: 'Untitled Sans';
          font-style: normal;
          font-weight: bold;
          font-display: swap;
          src: local('Untitled Sans'), local('UntitledSans-Bold'),
            url(/fonts/UntitledSans/UntitledSans-Bold.ttf)
              format('truetype');
            url(/fonts/UntitledSans/UntitledSans-Bold.eot)
              format('embedded-opentype');
            url(/fonts/UntitledSans/UntitledSans-Bold.otf)
              format('opentype');
            url(/fonts/UntitledSans/UntitledSans-Bold.svg#UntitledSans-Bold)
              format('svg');
            url(/fonts/UntitledSans/UntitledSans-Bold.woff)
              format('woff');
            url(/fonts/UntitledSans/UntitledSans-Bold.woff2)
              format('woff2');
          unicode-range: U+0100-024f, U+1-1eff, U+20a0-20ab, U+20ad-20cf,
            U+2c60-2c7f, U+A720-A7FF;
        }
        @font-face {
          font-family: 'Sharp Grotesk';
          font-style: normal;
          font-weight: 400;
          font-display: swap;
          src: local('Sharp Grotesk'), local('SharpGrotesk-Book'),
            url(/fonts/SharpGrotesk/SharpGrotesk-Book20.ttf)
              format('truetype');
            url(/fonts/SharpGrotesk/SharpGrotesk-Book20.eot)
              format('embedded-opentype');
            url(/fonts/SharpGrotesk/SharpGrotesk-Book20.otf)
              format('opentype');
            url(/fonts/SharpGrotesk/SharpGrotesk-Book20.svg#SharpGrotesk-Book20)
              format('svg');
            url(/fonts/SharpGrotesk/SharpGrotesk-Book20.woff)
              format('woff');
            url(/fonts/SharpGrotesk/SharpGrotesk-Book20.woff2)
              format('woff2');
          unicode-range: U+0100-024f, U+1-1eff, U+20a0-20ab, U+20ad-20cf,
            U+2c60-2c7f, U+A720-A7FF;
        }
        @font-face {
          font-family: 'Sharp Grotesk Bold';
          font-style: normal;
          font-weight: 700;
          font-display: swap;
          src: local('Sharp Grotesk Bold'), local('SharpGrotesk-Bold'),
            url(/fonts/SharpGrotesk/SharpGrotesk-Bold20.ttf) format('truetype'),
            url(/fonts/SharpGrotesk/SharpGrotesk-Bold20.eot) format('embedded-opentype'),
            url(/fonts/SharpGrotesk/SharpGrotesk-Bold20.otf) format('opentype'),
          unicode-range: U+0100-024f, U+1-1eff, U+20a0-20ab, U+20ad-20cf, U+2c60-2c7f, U+A720-A7FF;
        }
        @font-face {
          font-family: 'Sharp Grotesk';
          font-style: normal;
          font-weight: 500;
          font-display: swap;
          src: local('Sharp Grotesk'), local('SharpGrotesk-Medium'),
            url(/fonts/SharpGrotesk/SharpGrotesk-Medium20.ttf)
              format('truetype');
            url(/fonts/SharpGrotesk/SharpGrotesk-Medium20.eot)
              format('embedded-opentype');
            url(/fonts/SharpGrotesk/SharpGrotesk-Medium20.otf)
              format('opentype');
            url(/fonts/SharpGrotesk/SharpGrotesk-Medium20.svg#SharpGrotesk-Medium20)
              format('svg');
            url(/fonts/SharpGrotesk/SharpGrotesk-Medium20.woff)
              format('woff');
            url(/fonts/SharpGrotesk/SharpGrotesk-Medium20.woff2)
              format('woff2');
          unicode-range: U+0100-024f, U+1-1eff, U+20a0-20ab, U+20ad-20cf,
            U+2c60-2c7f, U+A720-A7FF;
        }

      body {
        color: #15191E;
      }
      strong, b {
        font-weight: 500;
      }
      // google-maps places api input dropdown item.
      .pac-item {
        display: inline-flex;
        align-items: center;
        width: 100%;
        height: 50px;
        cursor: pointer;
      }

      @keyframes logoSpin {
        0% {
          transform: rotate(0);
        }
        100% {
          transform: rotate(-720deg);
        }
      }
      `,
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: theme.palette.content.light,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          height: '50px',
          fontSize: '1rem',
          lineHeight: '1.5rem',
          textTransform: 'initial',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        outlined: {
          borderColor: '#B4EB47',
          '&:hover': {
            backgroundColor: '#B4EB47',
            borderColor: '#B4EB47',
            borderWidth: '1px',
          },
        },
        sizeSmall: {
          height: '36px',
          fontSize: '14px',
          lineHeight: '20px',
          boxShadow: 'none',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          '&::before': {
            backgroundColor: 'initial',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: '40px',
        },
        flexContainer: {
          gap: '28px',
        },
        indicator: {
          backgroundColor: '#000000',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: theme.palette.outline.main,
          fontSize: '16px',
          lineHeight: '24px',
          minHeight: '40px',
          minWidth: '0',
          padding: '8px 0',
          textTransform: 'none',
          '&.Mui-selected': {
            color: '#000000',
          },
        },
      },
    },
  },
});

export default theme;
