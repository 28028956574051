import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { APICall, MAHouseholdMembers, NYHouseholdMembers } from 'types';
import { BaseWithNotSureOption, State } from 'types/general';
import {
  HeaConfirmationOption,
  InsulationOption,
  JobComponentInputs,
  Preference,
  RecommendationsResponse,
  SaveInfo,
  SystemType,
} from 'types/onboarding';
import { HomeDetails as RebateEligibilityHomeDetails } from 'types/rebate-eligibility';
import { API_Endpoint } from 'utils/api';
import { OnboardingState } from 'hooks/useOnboardingState';

export interface Rebates {
  heat_pump: string;
  heat_pump_water_heater: string;
  home_energy_assessment: string;
  insulation: string;
}

export interface TaxIncentives {
  heat_pump: string;
  electric_panel_upgrade: string;
  insulation: string;
}

export const onboardingApi = createApi({
  reducerPath: 'onboardingApi',
  baseQuery: fetchBaseQuery({ baseUrl: API_Endpoint }),
  tagTypes: ['getHomeDetails', 'getRecommendations'],
  endpoints: (builder) => ({
    leadFormSubmit: builder.mutation<
      { existing_lead_id: String; redirect_url: string },
      {
        form_data: {
          fields: {
            full_name: string;
            address: string;
            email: string;
            phone_number: string;
            marketing_consent: string;
            hea?: string;
            home_occupancy?: string;
            insulation?: string;
            fuel_assistance?: string;
          };
          rebate_flow: boolean;
        };
      }
    >({
      query: (formDataInput) => {
        const formData = new FormData();
        (
          Object.keys(formDataInput.form_data.fields) as Array<
            keyof typeof formDataInput.form_data.fields
          >
        ).forEach((key) => {
          const value = formDataInput.form_data.fields[key];
          if (value) {
            formData.append(`fields[${key}]`, value);
          }
        });

        formData.append(
          'rebate_flow',
          String(formDataInput.form_data.rebate_flow)
        );

        return {
          url: 'ecomm/lead_form_submit',
          method: 'POST',
          body: formData,
        };
      },
    }),
    saveInfo: builder.mutation<
      { status: string; home_data_id: string },
      SaveInfo
    >({
      query: (saveInfo) => ({
        url: 'ecomm/save_info',
        method: 'POST',
        body: saveInfo,
      }),
      invalidatesTags: ['getHomeDetails'],
    }),
    leadOwner: builder.query<
      {
        calendly_link: string;
        email: string;
        id: string;
        name: string;
        phone: string;
      },
      string
    >({
      query: (phone) => {
        const queryParams = new URLSearchParams({ phone });
        return `ecomm/lead_owner?${queryParams}`;
      },
    }),
    getHomeDetails: builder.query<
      {
        data: {
          home_data: RebateEligibilityHomeDetails;
          postal_address: {
            city: string;
            state: string;
            street_address: string;
            zip_code: string;
          };
        };
      },
      {
        address: string;
      }
    >({
      query: ({ address }) => ({
        url: `ecomm/lookup`,
        method: 'POST',
        body: { address },
      }),
      providesTags: ['getHomeDetails'],
    }),
    isServiceable: builder.query<
      {
        home_data_id: null | string;
        status: 'serviceable' | 'not_serviceable';
      },
      {
        address: string;
      }
    >({
      query: ({ address }) => ({
        url: `ecomm/is_serviceable`,
        method: 'POST',
        body: { address },
      }),
    }),
    rebates: builder.query<
      {
        rebates: Rebates;
        tax_incentives: TaxIncentives;
        ny_rebate_program?: string;
      },
      {
        customer_email: string;
        hea: HeaConfirmationOption;
        homeDataId: string;
        homeOccupancy: BaseWithNotSureOption;
        householdIncome: string;
        householdMembers: string;
        insulationMeasure: InsulationOption;
        state: State;
      }
    >({
      query: ({
        customer_email,
        hea,
        homeDataId,
        homeOccupancy,
        householdMembers,
        householdIncome,
        insulationMeasure,
        state,
      }) => ({
        url: `ecomm/rebates`,
        method: 'POST',
        body: {
          customer_email,
          hea: hea === 'had-assessment',
          insulation_measure: insulationMeasure,
          home_data_id: homeDataId,
          household_members:
            householdMembers === '' ? undefined : householdMembers,
          household_income:
            householdIncome === '' ? undefined : householdIncome,
          summer_home: homeOccupancy === 'no',
          state,
        },
      }),
    }),
    futureLead: builder.mutation<
      APICall,
      {
        name?: string;
        address: string;
        email: string;
        phone?: string;
        fuel_assistance?: string;
        household_members?: string;
        household_income?: string;
        home_occupancy?: string;
        hea?: string;
        insulation_measure?: string;
      }
    >({
      query: ({
        name,
        address,
        email,
        phone,
        fuel_assistance,
        household_members,
        household_income,
        home_occupancy,
        hea,
        insulation_measure,
      }) => ({
        url: `ecomm/future_lead`,
        method: 'POST',
        body: {
          address,
          email,
          fuel_assistance: fuel_assistance ? fuel_assistance === 'yes' : null,
          hea: hea ? hea === 'yes' : null,
          household_income: household_income ? household_income : null,
          household_members: household_members ? household_members : null,
          insulation_measure: insulation_measure ? insulation_measure : null,
          name,
          phone,
          summer_home: home_occupancy ? home_occupancy === 'no' : null,
          page: window.location.pathname,
        },
      }),
    }),
    getRecommendations: builder.query<
      RecommendationsResponse,
      {
        homeDataId: string;
        selectedSystemTypes?: SystemType[];
        selectedSystemManufacturer?: string;
        preferenceRanking: Preference;
        dmsExpectationsCeilingCassette: number; // true or false
        rebateEligible: string; // true or false
      }
    >({
      query: ({
        homeDataId,
        selectedSystemTypes,
        selectedSystemManufacturer,
        preferenceRanking,
        dmsExpectationsCeilingCassette,
        rebateEligible,
      }) => {
        const queryParams = new URLSearchParams({
          home_data_id: homeDataId,
          preference_ranking: preferenceRanking,
          dms_expectations_ceiling_cassette:
            dmsExpectationsCeilingCassette.toString(),
          rebate_eligible: rebateEligible,
        });

        selectedSystemTypes?.forEach((systemType) => {
          queryParams.append('selected_system_types', systemType);
        });

        if (selectedSystemManufacturer)
          queryParams.append(
            'selected_system_manufacturer',
            selectedSystemManufacturer
          );

        return `ecomm/recommendations?${queryParams}`;
      },
      providesTags: ['getRecommendations'],
    }),
    updateLeadOwner: builder.mutation<
      {
        status: string;
        data: {
          manager: {
            calendly_link: string;
            email: string;
            name: string;
            phone: string;
            id: string;
          };
        };
      },
      {
        customer: {
          name: string;
          address: string;
          email: string;
          phone: string;
        };
      }
    >({
      query: (body) => ({
        url: 'ecomm/update_lead_owner',
        method: 'POST',
        body,
      }),
    }),
    selectSystem: builder.mutation<
      {
        status: string;
        data: {
          manager: {
            email: string;
            name: string;
            phone: string;
            id: string;
            calendly_link: string;
          };
          proposal_id: string;
        };
      },
      {
        customer: {
          name: string;
          address: string;
          email: string;
          phone: string;
        };
        systems: {
          id?: string;
          system_type: SystemType;
          condensers?: { id: string; system_type: string }[];
          heads?: {
            id: string;
            floor: number;
            room_type: string;
            room_name: string;
          }[];
        }[];
        job_component_inputs: JobComponentInputs;
        rebate_qualified: boolean;
        home_data_id: string;
      }
    >({
      query: (body) => ({
        url: 'ecomm/select_system',
        method: 'POST',
        body,
      }),
    }),
    createOutOfTerritoryLead: builder.mutation<
      null,
      {
        form_data: {
          fields: {
            full_name: string;
            address: string;
            email: string;
            phone_number: string;
            marketing_consent: string;
          };
          rebate_flow: boolean;
        };
      }
    >({
      query: (formDataInput) => {
        const formData = new FormData();
        (
          Object.keys(formDataInput.form_data.fields) as Array<
            keyof typeof formDataInput.form_data.fields
          >
        ).forEach((key) => {
          const value = formDataInput.form_data.fields[key];
          if (value) {
            formData.append(`fields[${key}]`, value);
          }
        });

        formData.append(
          'rebate_flow',
          String(formDataInput.form_data.rebate_flow)
        );

        return {
          url: 'ecomm/create_out_of_territory_lead',
          method: 'POST',
          body: formData,
        };
      },
    }),
    recommendSystemType: builder.query<
      { recommended_system_type: 'DMS' | 'central_heat_pump' },
      { homeDataId: string }
    >({
      query: ({ homeDataId }) => {
        const queryParams = new URLSearchParams({
          home_data_id: homeDataId,
        });

        return `ecomm/recommend_system_type?${queryParams}`;
      },
    }),
    createDepositIntent: builder.mutation<
      {
        client_secret: string;
      },
      { proposal_id: string }
    >({
      query: (body) => ({
        url: 'ecomm/create_deposit_intent',
        method: 'POST',
        body,
      }),
    }),
    acceptProposal: builder.mutation<
      {
        manager: {
          email: string;
          name: string;
          phone: string;
          id: string;
          calendly_link: string;
        };
      },
      { proposal_id: string }
    >({
      query: (body) => ({
        url: 'ecomm/accept_proposal',
        method: 'POST',
        body,
      }),
    }),
    selectPaymentMethod: builder.mutation<
      { proposalId: string },
      {
        proposal: string;
        payment_method: 'cash' | 'loan';
        loan_name?: string;
      }
    >({
      query: (body) => ({
        url: 'ecomm/select_payment_method',
        method: 'POST',
        body,
      }),
    }),
    getOnboarding: builder.query<
      OnboardingState,
      {
        address: string;
        email: string;
        phone: string;
      }
    >({
      query: ({ address, email, phone }) => {
        const queryParams = new URLSearchParams({
          address,
          email,
          phone,
        });

        return `ecomm/state?${queryParams}`;
      },
    }),
    saveOnboarding: builder.mutation<
      {
        status: string;
        home_data: any;
        onboarding: any;
      },
      any
    >({
      query: (body) => {
        return {
          url: 'ecomm/save',
          method: 'POST',
          body: {
            onboarding: {
              ...body.onboarding,
              page: window.location.pathname,
            },
            home_data: body.home_data,
          },
        };
      },
      onQueryStarted: async (body, { dispatch, queryFulfilled }) => {
        // pessimistic update
        const { data } = await queryFulfilled;
        dispatch(
          onboardingApi.util.updateQueryData(
            'getOnboarding',
            {
              address: body.onboarding.address,
              email: body.onboarding.email,
              phone: body.onboarding.phone,
            },
            (cachedData) => {
              if (cachedData) {
                Object.assign(cachedData, data);
              } else {
                return data;
              }
            }
          )
        );
      },
      invalidatesTags: ['getRecommendations'],
    }),
  }),
});

export const {
  useLeadFormSubmitMutation,
  useSaveInfoMutation,
  useLeadOwnerQuery,
  useGetHomeDetailsQuery,
  useIsServiceableQuery,
  useRebatesQuery,
  useFutureLeadMutation,
  useGetRecommendationsQuery,
  useUpdateLeadOwnerMutation,
  useSelectSystemMutation,
  useCreateOutOfTerritoryLeadMutation,
  useRecommendSystemTypeQuery,
  useCreateDepositIntentMutation,
  useAcceptProposalMutation,
  useSelectPaymentMethodMutation,
  useGetOnboardingQuery,
  useSaveOnboardingMutation,
} = onboardingApi;
